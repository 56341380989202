@media (max-width: 768px) {
  .h3 {
      font-size: 1.2rem;
  }
  h3 {
      font-size: 1.2rem;
  }
  p.text-left.text-primary{
      font-size: 1.0rem;
      line-height: 1.2rem;
  }
}



/************BOOTSTRAP4 PERSONAL INVALID INPUT-GROUP-ADDON*********************************/
.was-validated .input-group-addon:invalid,
.input-group-addon.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: #dc3545;
	color: #dc3545;
	background-color: #f2dede;
}

.was-validated .input-group-addon:valid,
.input-group-addon.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
	border-color: #28a745;
	color: #3c763d;
	background-color: #dff0d8;
}

.is-invalid textarea,
.is-invalid label {
	color: #a94442;
	border-color: #a94442;
}

.is-valid textarea,
.is-valid label {
	color: #3c763d;
	border-color: #3c763d;
}



select.form-control:not([size]):not([multiple]) {
    height: auto!important;
}
tbody {
	width: 100%;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	border-radius: 35px;
	font-size: 24px;
	line-height: 1.33;
}
.tbody td.small p{
	line-height: 1.0;
}



input.tam{
	width: 85% !important;
}
tbody {
	width: 100%;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	border-radius: 35px;
	font-size: 24px;
	line-height: 1.33;
}
.tbody td.small p{
	line-height: 1.0;
}


.react-datepicker__day--highlighted-custom-1{
	color: #000;
	background-color:#ffc107; 
	font-weight: bold;
}

.react-datepicker__day--highlighted-custom-2{
	color: #fff;
	background-color: #6c757d;
	font-weight: bold;
}

tbody {
	width: 100%;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	border-radius: 35px;
	font-size: 24px;
	line-height: 1.33;
}
.tbody td.small p{
	line-height: 1.0;
}

.custom-file-input:lang(en) ~ .custom-file-label::after{
    content: "Elegir";
}
tbody {
	width: 100%;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	border-radius: 35px;
	font-size: 24px;
	line-height: 1.33;
}
.tbody td.small p{
	line-height: 1.0;
}

tbody {
	width: 100%;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	border-radius: 35px;
	font-size: 24px;
	line-height: 1.33;
}
.tbody td.small p{
	line-height: 1.0;
}


/* Extra small devices (portrait phones, less than 576px) 
 No media query since this is the default in Bootstrap 
html {
    font-size: 1rem;
}

 Small devices (landscape phones, 576px and up) 
 */
@media (min-width: 768px) {
    .navbar-nav {
        font-size: 1.6rem;
    }
}
/*
 Medium devices (tablets, 768px and up) 
@media (min-width: 768px) {
    html {
        font-size: 1.4rem;
    }
}

 Large devices (desktops, 992px and up) 
@media (min-width: 992px) {
    html {
        font-size: 1.6rem;
    }
}

 Extra large devices (large desktops, 1200px and up)
// (you don't need this one unless you want something different from LG)
@media (min-width: 1200px) {
    html {
        font-size: 1.6rem;
    }
} */


/************BOOTSTRAP4 PERSONAL INVALID INPUT-GROUP-ADDON*********************************/
.was-validated .input-group-addon:invalid,
.input-group-addon.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: #dc3545;
	color: #dc3545;
	background-color: #f2dede;
}

.was-validated .input-group-addon:valid,
.input-group-addon.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
	border-color: #28a745;
	color: #3c763d;
	background-color: #dff0d8;
}

.is-invalid textarea,
.is-invalid label {
	color: #a94442;
	border-color: #a94442;
}

.is-valid textarea,
.is-valid label {
	color: #3c763d;
	border-color: #3c763d;
}


tbody {
	width: 100%;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	border-radius: 35px;
	font-size: 24px;
	line-height: 1.33;
}
.tbody td.small p{
	line-height: 1.0;
}


.react-datepicker__day--highlighted-custom-1{
	color: #000;
	background-color:#ffc107; 
	font-weight: bold;
}

.react-datepicker__day--highlighted-custom-2{
	color: #fff;
	background-color: #6c757d;
	font-weight: bold;
}

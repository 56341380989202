@media (max-width: 768px) {
  .h3 {
      font-size: 1.2rem;
  }
  h3 {
      font-size: 1.2rem;
  }
  p.text-left.text-primary{
      font-size: 1.0rem;
      line-height: 1.2rem;
  }
}

/* Extra small devices (portrait phones, less than 576px) 
 No media query since this is the default in Bootstrap 
html {
    font-size: 1rem;
}

 Small devices (landscape phones, 576px and up) 
 */
@media (min-width: 768px) {
    .navbar-nav {
        font-size: 1.6rem;
    }
}
/*
 Medium devices (tablets, 768px and up) 
@media (min-width: 768px) {
    html {
        font-size: 1.4rem;
    }
}

 Large devices (desktops, 992px and up) 
@media (min-width: 992px) {
    html {
        font-size: 1.6rem;
    }
}

 Extra large devices (large desktops, 1200px and up)
// (you don't need this one unless you want something different from LG)
@media (min-width: 1200px) {
    html {
        font-size: 1.6rem;
    }
} */
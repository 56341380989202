tbody {
	width: 100%;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	border-radius: 35px;
	font-size: 24px;
	line-height: 1.33;
}
.tbody td.small p{
	line-height: 1.0;
}

.custom-file-input:lang(en) ~ .custom-file-label::after{
    content: "Elegir";
}